@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Sora', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0a091a;
}

* {
    font-family: 'Sora', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
